<template>
    <div class="clientcabin clientcabin-examples">
        <v-container>
            <v-row justify="center" class="align-center pt-md-6">
                <v-col cols="12" lg="9" xl="7" class="pb-0">
                    <div id="report" class="subtitle text-center">
                        Organic Visibility Snapshot Report of Opportunities &
                        Competition
                    </div>
                </v-col>
            </v-row>
            <v-row justify="center" class="align-center">
                <v-col cols="12" lg="9" xl="7" class="justify-center pb-md-10">
                    <v-img
                        eager
                        class="link"
                        src="@/assets/img/clientcabin/examples/report-preview.png"
                        @click="
                            open(
                                '/files/doc/Organic-Visibility-Snapshot-Report-of-Opportunities-&-Competition.pdf'
                            )
                        "
                    />
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7">
                    <v-divider />
                </v-col>
            </v-row>
            <v-row justify="center" class="align-center py-md-6">
                <v-col cols="12" lg="9" xl="7" class="pb-0">
                    <div id="article" class="subtitle text-center">
                        Done-For-You News Article
                    </div>
                </v-col>
            </v-row>
            <v-row justify="center" class="align-center">
                <v-col cols="12" lg="9" xl="7" class="justify-center pb-md-10">
                    <v-img
                        eager
                        class="link"
                        src="@/assets/img/clientcabin/examples/article-preview.jpg"
                        @click="
                            open('/files/doc/Done-For-You-News-Article.jpg')
                        "
                    />
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7">
                    <v-divider />
                </v-col>
            </v-row>
            <v-row justify="center" class="align-center py-md-6">
                <v-col cols="12" lg="9" xl="7" class="pb-0">
                    <div id="audio" class="subtitle text-center">
                        Done-For-You Audio Ad
                    </div>
                </v-col>
            </v-row>
            <v-row justify="center" class="align-center">
                <v-col cols="12" lg="9" xl="7" class="justify-center pb-md-10">
                    <v-img
                        eager
                        src="@/assets/img/clientcabin/examples/podcast-preview.jpg"
                    />
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7">
                    <v-divider />
                </v-col>
            </v-row>
            <v-row justify="center" class="align-center py-md-6">
                <v-col cols="12" lg="9" xl="7" class="pb-0">
                    <div id="blog" class="subtitle text-center">
                        Done-For-You Blog Post
                    </div>
                </v-col>
            </v-row>
            <v-row justify="center" class="align-center">
                <v-col cols="12" lg="9" xl="7" class="justify-center pb-md-10">
                    <v-img
                        eager
                        class="link"
                        src="@/assets/img/clientcabin/examples/blog-preview.jpg"
                        @click="open('/files/doc/Done-For-You-Blog-Post.jpg')"
                    />
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7">
                    <v-divider />
                </v-col>
            </v-row>
            <v-row justify="center" class="align-center py-md-6">
                <v-col cols="12" lg="9" xl="7" class="pb-0">
                    <div id="video" class="subtitle text-center">
                        Done-For-You Video Ad
                    </div>
                </v-col>
            </v-row>
            <v-row justify="center" class="align-center">
                <v-col cols="12" lg="9" xl="7" class="justify-center pb-md-10">
                    <v-img
                        eager
                        src="@/assets/img/clientcabin/examples/video-preview.jpg"
                    />
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7">
                    <v-divider />
                </v-col>
            </v-row>
            <v-row justify="center" class="align-center py-md-6">
                <v-col cols="12" lg="9" xl="7" class="pb-0">
                    <div id="slideshow" class="subtitle text-center">
                        Done-For-You Slideshow
                    </div>
                </v-col>
            </v-row>
            <v-row justify="center" class="align-center">
                <v-col cols="12" lg="9" xl="7" class="justify-center pb-md-10">
                    <v-img
                        eager
                        class="link"
                        src="@/assets/img/clientcabin/examples/slideshow-preview.jpg"
                        @click="open('/files/doc/Done-For-You-Slideshow.jpg')"
                    />
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7">
                    <v-divider />
                </v-col>
            </v-row>
            <v-row justify="center" class="align-center py-md-6">
                <v-col cols="12" lg="9" xl="7" class="pb-0">
                    <div id="infographic" class="subtitle text-center">
                        Done-For-You Infographic
                    </div>
                </v-col>
            </v-row>
            <v-row justify="center" class="align-center">
                <v-col cols="12" lg="9" xl="7" class="justify-center pb-md-10">
                    <v-img
                        eager
                        class="link"
                        src="@/assets/img/clientcabin/examples/infographic-preview.jpg"
                        @click="open('/files/doc/Done-For-You-Infographic.jpg')"
                    />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { ExternalVideoEmbed } from '@/components/ExternalVideoEmbed';
import { getScrollParent } from '@/utils/helpers';

@Component({
    name: 'Examples',
    components: {
        ExternalVideoEmbed
    }
})
export default class Examples extends Vue {
    open(url: string) {
        Object.assign(
            document.createElementNS('http://www.w3.org/1999/xhtml', 'a'),
            {
                target: '_blank',
                href: url
            }
        ).click();
    }

    created() {
        if (window.location.hash) {
            setTimeout(() => {
                this.scrollTo(window.location.hash);
            }, 1000);
        }
    }

    scrollTo(href: string) {
        const element = document.getElementById(href.replace('#', ''));

        if (element) {
            const container = getScrollParent(element) as HTMLElement;

            if (container) {
                this.$vuetify.goTo(element, {
                    container,
                    offset: 20
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.clientcabin-examples::v-deep {
    background-color: $white;
    color: $black;

    .headline {
        font-size: 48px !important;
        line-height: 60px !important;
        letter-spacing: 0.02em;
        font-weight: 700;
        color: #282b30;
    }

    .subheadline {
        font-size: 40px !important;
        line-height: 48px !important;
        letter-spacing: 0.035em;
        font-weight: 700;
        color: #282b30;
    }

    .subtitle {
        font-size: 20px;
        line-height: 36px;
        font-weight: 600;
    }

    .link {
        cursor: pointer;
    }
}
</style>
